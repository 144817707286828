<template>
  <va-card v-if="showGrid" title="Platform">
    <template slot="actions">
      <va-button color="success" icon="fa fa-plus" @click="showGrid = false">Add</va-button>
      <va-button color="orange" icon="entypo entypo-export" @click="exportData()">Export</va-button>
    </template>
    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          placeholder="search"
          @input="search"
          removable>
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <va-data-table
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)">
      <template slot="edit" slot-scope="props">
        <va-button flat small 
        color="gray" icon="fa fa-pencil" 
        @click="editPlatform(props.rowData)" 
        class="ma-0"/>
      </template>
    </va-data-table>
  </va-card>
  <va-card v-else :title="isCreate?'create platform':'update platform'">
    <template slot="actions">
      <va-button icon="fa fa-list"
        @click="resetValue()" color="gray">List</va-button>
    </template>
    <form @submit.prevent="isCreate?addPlatform():updatePlatform()">
      <va-input
        label="Platform Name *"
        pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"
        type="text"
        placeholder="Enter Platform Name"
        v-model="platform.platform_name"
        :error="!!error.platform_name.value.length"
        :error-messages="error.platform_name.value"
      />
      <va-input
        label="Description *"
        :pattern="regex.nameOnly"
        type="text"
        placeholder="Enter Description"
        v-model="platform.description"
        :error="!!error.description.value.length"
        :error-messages="error.description.value"
      />
      <va-select
        label="Installtion Type *"
        placeholder="Select Installtion Type"
        v-model="platform.installation_type"
        :options="installOtns"
        :error="!!error.installation_type.value.length"
        :error-messages="error.installation_type.value"
      />
      <va-input
        label="OTC License Fee*"
        type="number"
        min=0
        placeholder="Enter OTC License"
        v-model="platform.otc_license"
        :error="!!error.otc_license.value.length"
        :error-messages="error.otc_license.value"
      />
      <va-select
        label="Renewal Mode *"
        placeholder="Select Renewal Mode"
        v-model="platform.renewal_mode"
        :options="renewalOtns"
        :error="!!error.renewal_mode.value.length"
        :error-messages="error.renewal_mode.value"
      />    
      <va-input
        label="Free License Credit *"
        type="number"
        min=0
        placeholder="Enter Free License Credit"
        v-model="platform.free_license_credits"
        :error="!!error.free_license_credits.value.length"
        :error-messages="error.free_license_credits.value"
      />  
      <va-input
        label="Renewal License Grace Period *"
        type="number"
        min=0
        placeholder="Enter Renewal License Grace Period"
        v-model="platform.renewal_license_grace_period"
        :error="!!error.renewal_license_grace_period.value.length"
        :error-messages="error.renewal_license_grace_period.value"
      />  
      <va-input
        label="Platform Username *"
        :pattern="regex.nameOnly"
        type="text"
        placeholder="Enter Username"
        v-model="platform.user_name"
        :error="!!error.user_name.value.length"
        :error-messages="error.user_name.value"
      />
      <va-input
        label="Platform Password * ( Maximum length is 20 characters )"
        :pattern="regex.password"
        type="password"
        minlength=8
        maxlength=20
        placeholder="Enter Password"
        v-model="platform.password"
        :error="!!error.password.value.length"
        :error-messages="error.password.value"
      />
      <va-input
        label="Platform Support User Name *"
        :pattern="regex.nameOnly"
        type="text"
        placeholder="Enter Support User Name"
        v-model="platform.support_user_name"
        :error="!!error.support_user_name.value.length"
        :error-messages="error.support_user_name.value"
      />
      <va-input
        label="Platform Support Password * ( Maximum length is 20 characters )"
        :pattern="regex.password"
        type="password"
        minlength=8
        maxlength=20
        placeholder="Enter Support Password"
        v-model="platform.support_password"
        :error="!!error.support_password.value.length"
        :error-messages="error.support_password.value"
      />
      <va-input
        label="Platform External Support User Name *"
        :pattern="regex.nameOnly"
        type="text"
        placeholder="Enter External Support User Name"
        v-model="platform.ext_support_user_name"
        :error="!!error.ext_support_user_name.value.length"
        :error-messages="error.ext_support_user_name.value"
      />
      <va-input
        label="Platform External Support Password * ( Maximum length is 20 characters )"
        :pattern="regex.password"
        type="password"
        minlength=8
        maxlength=20
        placeholder="Enter External Support Password"
        v-model="platform.ext_support_password"
        :error="!!error.ext_support_password.value.length"
        :error-messages="error.ext_support_password.value"
      />
      <va-input
        label="Platform Advance Support User Name *"
        :pattern="regex.nameOnly"
        type="text"
        placeholder="Enter Advance Support User Name"
        v-model="platform.adv_support_user_name"
        :error="!!error.adv_support_user_name.value.length"
        :error-messages="error.adv_support_user_name.value"
      />
      <va-input
        label="Platform Advance Support Password * ( Maximum length is 20 characters )"
        :pattern="regex.password"
        type="password"
        minlength=8
        maxlength=20
        placeholder="Enter Advance Support Password"
        v-model="platform.adv_support_password"
        :error="!!error.adv_support_password.value.length"
        :error-messages="error.adv_support_password.value"
      />
      <va-input
        label="Platform Management IP *"
        :pattern="regex.ip"
        type="text"
        placeholder="Enter Management IP"
        v-model="platform.mgmnt_ip"
        :error="!!error.mgmnt_ip.value.length"
        :error-messages="error.mgmnt_ip.value"
      />
      <va-input
        label="Platform Management Port *"
        :pattern="regex.port"
        type="text"
        placeholder="Enter Management Port"
        v-model="platform.mgmnt_port"
        :error="!!error.mgmnt_port.value.length"
        :error-messages="error.mgmnt_port.value"
      />
      <div class="d-flex justify--center mt-3">
        <va-button class="my-0" flat @click="resetValue()">Cancel</va-button>
        <va-button type="submit" class="my-0">{{isCreate?'Create':'Update'}}</va-button>
      </div>
    </form>
  </va-card>
</template>

<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)

export default {
  created(){
    this.getPlatforms()
  },
  data () {
    return {
      term:null,
      perPage:'10',
      perPageOptions:['4', '6', '10', '20'],
      showGrid:true,
      isCreate:true,
      platform_id:'',
      installOtns:["SINGLE","MULTIPLE"],
      renewalOtns:["MONTHLY","ANNUALLY","NEVER","DRM BASED"],
      platforms: [],
      regex:{
        ip:"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$",
        port:"^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$",
        nameOnly:"^[A-Za-z ]+$",
        password:"^[A-Za-z0-9][A-Za-z0-9_@!./#&+-^]*$"
      },
      platform:{
        platform_name:"",
        description:"",
        installation_type:"",
        otc_license:"",
        renewal_mode:"",
        free_license_credits:"",
        renewal_license_grace_period:"",
        user_name:"",
        password:"",
        support_user_name:"",
        support_password:"",
        ext_support_user_name:"",
        ext_support_password:"",
        adv_support_user_name:"",
        adv_support_password:"",
        mgmnt_ip:"",
        mgmnt_port:"",
      },
      error:{
        platform_name: {
          name : "Platform Name",
          value: []
        },
        description: {
          name : "Description",
          value: []
        },
        installation_type: {
          name : "Installation Type",
          value: []
        },
        otc_license: {
          name : "OTC License",
          value: []
        },
        renewal_mode: {
          name : "Renewal mode",
          value: []
        },
        free_license_credits: {
          name : "Free license credits",
          value: []
        },
        renewal_license_grace_period: {
          name : "Renwal license grace period",
          value: []
        },
        user_name: {
          name : "username",
          value: []
        },
        password: {
          name : "Password",
          value: []
        },
        support_user_name: {
          name : "Support username",
          value: []
        },
        support_password: {
          name : "Support password",
          value: []
        },
        ext_support_user_name: {
          name : "External Support User Name",
          value: []
        },
        ext_support_password: {
          name : "External Support Password",
          value: []
        },
        adv_support_user_name: {
          name : "Advance Support User Name",
          value: []
        },
        adv_support_password: {
          name : "Advance Support Password",
          value: []
        },
        mgmnt_ip: {
          name : "Management IP",
          value: []
        },
        mgmnt_port: {
          name : "Management Port",
          value: []
        },
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'sno',
          width: "8%"
        },
        {
          name: 'platform_name',
          title: 'Platform Name',
          width: "20%"
        },
        {
          name: 'installation_type',
          title: 'Installtion Type',
          width: "13%"
        },
        {
          name: 'otc_license',
          title: 'OTC License',
          width: "14%"
        },
        {
          name: 'renewal_mode',
          title: 'Renewal Mode',
          width: "15%"
        },
        {
          name: 'user_name',
          title: 'Username',
          width: "16%"
        },
        {
          name: 'mgmnt_ip',
          title: 'Management IP',
          width: "16%"
        },
        {
          name: 'mgmnt_port',
          title: 'Management Port',
          width: "16%"
        },
        {
          name: '__slot:edit',
          dataClass: 'text-right',
          width: "20%"
        }
      ]
    },
    filteredData () {
      let filteredPlatform = search(this.term, this.platforms)
      return filteredPlatform
    }
  },
  methods:{
    exportData(){
      var rowKeys = {
        platform_name: 'Platform Name',
        installation_type: 'Installtion Type',
        user_name: 'User Name',
        mgmnt_ip: 'Management IP',
        mgmnt_port:'Management Port'
      }
      return exportToFile(this.platforms, rowKeys, 'Platform', 'CSV')
    },
    editPlatform(platform){
      Object.entries(this.platform).map(key => {
        if(platform[key[0]]) this.platform[key[0]] = platform[key[0]]
      })
      this.platform_id = platform.platform_id
      this.showGrid = false
      this.isCreate = false
    },
    resetValue(){
      this.showGrid = true
      this.isCreate = true
      Object.entries(this.platform).forEach(key => this.platform[key[0]] = '')
      Object.entries(this.error).forEach(key => this.error[key[0]].value = [])
    },
    validatePlatform(){
      Object.entries(this.platform).forEach(key => {
        if (!this.platform[key[0]])
          this.error[key[0]].value = [`${this.error[key[0]].name} Required`]
        else
          this.error[key[0]].value = []
      })
      let validation = Object.entries(this.error).every(key => !this.error[key[0]].value.length)
      return validation
    },
    getPlatforms(){
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host+'platform')
      .then(res => {
        loader.hide()
        this.platforms = res.data
        this.platforms.map((platform,i) => platform.sno = i+1)
      })
      .catch(error => {
        loader.hide()
        Vue.notify({ text: error.body, type: 'error'})
      })
    },
    addPlatform(){
      if (!this.validatePlatform()) return
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host+'platform',this.platform)
      .then(res => {
        loader.hide()
        Vue.notify({text: res.body, type: 'success'})
      })
      .catch(err => {
        loader.hide()
        Vue.notify({ text: err.body, type: 'error'})
      })
      .then(() => {
        this.resetValue()
        this.getPlatforms()
      })
    },
    updatePlatform(){
      if (!this.validatePlatform()) return
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host+`platform/${this.platform_id}`,this.platform)
      .then(res => {
        loader.hide()
        Vue.notify({ text: res.body, type: 'success'})
      })
      .catch(err => {
        loader.hide()
        Vue.notify({text: err.body, type: 'error'})
      })
      .then(() => {
        this.resetValue()
        this.getPlatforms()
      })
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  }
}
</script>

<style scoped>
.badge {
  padding: 8px 0.5rem;
}
</style>